import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { Routes, RouterModule } from '@angular/router';
import { PageGuard } from './guards/page.guard';
import { HeaderGuard } from './guards/header.guard';
import { FooterGuard } from './guards/footer.guard';
import { PostsGuard } from './guards/posts.guard';
import { PostGuard } from './guards/post.guard';
import { LocationGuard } from './guards/location.guard';
import { LocationsGuard } from './guards/locations.guard';
import { BaseComponent } from './components/base/base.component';
import { MapComponent } from './components/map/map.component';
// import { MapLocationComponent } from './components/map-location/map-location.component';
import { MapvisitComponent } from './components/map-visit/map-visit.component';
import { PageComponent } from './components/page/page.component';
import { PagePostsComponent } from './components/page-posts/page-posts.component';
import { PagePostComponent } from './components/page-post/page-post.component';
// import { ComingComponent } from './components/coming/coming.component';
import { TeachersComponent } from './components/teachers/teachers.component';
// import { routerTransition } from './router.animations';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    resolve: {
      header: HeaderGuard,
      footer: FooterGuard
    },
    children: [
      {
        path: 'map',
        component: MapComponent,
        resolve: {
          content: PageGuard,
          posts: LocationsGuard
        },
        // redirectTo: 'coming',
        data: {animation: 'Map'}
      },
      {
        path: 'map/:slug',
        component: MapvisitComponent,
        resolve: {
          posts: LocationGuard
        },
        // redirectTo: 'coming',
        data: {animation: 'Map'}
      },
      /* {
        path: 'blog',
        component: PagePostsComponent,
        resolve: {
          content: PageGuard,
          posts: PostsGuard
        },
        // redirectTo: 'coming',
        data: {animation: 'Blog'}
      },
      {
        path: 'coming',
        component: ComingComponent,
        resolve: {
          content: PageGuard,
          posts: PostsGuard
        },
        data: {animation: 'Coming'}
      }, */
      {
        path: 'teachers',
        component: TeachersComponent,
        resolve: {
          content: PageGuard,
          posts: PostsGuard
        },
        data: {animation: 'Coming'}
      },
      /* {
        path: 'blog/:slug',
        component: PagePostComponent,
        resolve: {
          content: PostGuard,
        },
        // redirectTo: 'coming',
        data: {animation: 'Post'}
      }, */
      {
        path: 'about',
        component: PageComponent,
        resolve: {
          content: PageGuard
        },
        // redirectTo: 'coming',
        data: {animation: 'Page'}
      },
      {
        path: '',
        // component: MapComponent,
        resolve: {
          content: PageGuard
        },
        redirectTo: 'map',
        pathMatch: 'full',
        data: {animation: 'Page'}
      },
      {
        path: 'blog',
        // component: PageComponent,
        resolve: {
          content: PageGuard
        },
        redirectTo: 'map',
        pathMatch: 'full',
        data: {animation: 'Page'}
      },
      {
        path: '**',
        component: PageComponent,
        resolve: {
          content: PageGuard
        },
        // redirectTo: 'coming',
        data: {animation: 'Page'}
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

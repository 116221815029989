import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post } from '../../interfaces/posts';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-map-visit',
  templateUrl: './map-visit.component.html',
  styleUrls: ['./map-visit.component.scss']
})
export class MapvisitComponent implements OnInit {
  post$: Observable<Post>;
  post: Post;
  showModal: Boolean = true;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.post$ = this.route.data.map(r => r.posts);

    this.post$.subscribe((post) => {
      this.post = post;
    });
  }

  closeModal() {
    return this.showModal = false;
  }

}

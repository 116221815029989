import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { WpApiModule, WpApiLoader, WpApiStaticLoader } from 'wp-api-angular';
import { Http } from '@angular/http';
import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { InlineSVGModule } from 'ng-inline-svg';
import { SimpleModalModule } from 'ngx-simple-modal';
import { BaseComponent } from './components/base/base.component';
import { PageComponent } from './components/page/page.component';
import { PageGuard } from './guards/page.guard';
import { HeaderGuard } from './guards/header.guard';
import { FooterGuard } from './guards/footer.guard';
import { PostsGuard } from './guards/posts.guard';
import { PostGuard } from './guards/post.guard';
import { LocationGuard } from './guards/location.guard';
import { LocationsGuard } from './guards/locations.guard';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PagePostsComponent } from './components/page-posts/page-posts.component';
import { PagePostComponent } from './components/page-post/page-post.component';
import { environment } from '../environments/environment';
import { MapComponent } from './components/map/map.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MapLocationComponent } from './components/map-location/map-location.component';
// import { ComingComponent } from './components/coming/coming.component';
// import { MailchimpComponent } from './components/mailchimp/mailchimp.component';
import { FamilyComponent } from './components/family/family.component';
import { SvgComponent } from './components/svg/svg.component';
import { MapvisitComponent } from './components/map-visit/map-visit.component';
import { SafePipe } from './safe.pipe';
import { TeachersComponent } from './components/teachers/teachers.component';
// import { MailchimpService } from './services/mailchimp.service';

export function WpApiLoaderFactory(http: Http) {
  return new WpApiStaticLoader(http, environment.apiUrl);
}

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    PageComponent,
    HeaderComponent,
    FooterComponent,
    PagePostsComponent,
    PagePostComponent,
    MapComponent,
    MapLocationComponent,
    FamilyComponent,
    SvgComponent,
    MapvisitComponent,
    SafePipe,
    TeachersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SlimLoadingBarModule.forRoot(),
    WpApiModule.forRoot({
      provide: WpApiLoader,
      useFactory: (WpApiLoaderFactory),
      deps: [Http]
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    InlineSVGModule,
    SimpleModalModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [HttpClient, HeaderGuard, FooterGuard, PageGuard, PostsGuard, PostGuard, LocationGuard, LocationsGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }


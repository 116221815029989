import { Component, OnInit, Injectable, AfterViewInit } from '@angular/core';
import * as SvgPanZoom from 'svg-pan-zoom';
import { Router } from '@angular/router';
import 'hammerjs';

@Injectable()
@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit, AfterViewInit {

  svgPanZoom: SvgPanZoom.Instance;
  svgOpts: Object;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.svgOpts = {
      zoomEnabled: true,
      mouseWheelZoomEnabled: false,
      controlIconsEnabled: true,
      fit: true,
      center: true,
      // contain: true,
    };
    this.svgPanZoom = SvgPanZoom('#cgm-map', this.svgOpts);
  }

  mapGo(pageName: string) {
    this.router.navigate(['map', `${pageName}`]);
  }

}

import { Component, OnInit, Injectable } from '@angular/core';

@Injectable()
@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

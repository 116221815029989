import { Injectable } from '@angular/core';
import { WpApiCustom } from 'wp-api-angular';
import { Resolve } from '@angular/router';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class LocationsGuard implements Resolve<any> {
  public locations;

  constructor(
    private wpApiPosts: WpApiCustom,
  ) {}

  resolve(): any {
    // Cache list of locations
    if (!this.locations) {
      return this.wpApiPosts.getInstance('city-location').getList().toPromise().then(response => response.json()).then(body => {
        this.locations = body;
        return this.locations;
      }).catch(error => {
        console.log('ERROR posts: retrieving WP Posts list');
        return {error: error};
      });
    } else {
      return this.locations;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Page } from '../../interfaces/page';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';

interface RegisterResponse {
  success: Boolean;
  data: String;
}

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent implements OnInit {

  comingMessage: String = 'Coming soon!';

  page$: Observable<Page>;
  page: Page;
  registered: Boolean = false;
  name: String;
  email: String;
  school: String;
  position: String;
  city: String;
  apiURL: String = environment.webUrl + 'wp-admin/admin-ajax.php?action=send_register';

  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.page$ = this.route.data.map(r => r.content);

    this.page$.subscribe((page) => {
      this.page = page;
    });
  }

  register_submit() {
    // this.postData
    this.httpClient.post<RegisterResponse>(this.apiURL
      + '&name=' + this.name
      + '&email=' + this.email
      + '&school=' + this.school
      + '&city=' + this.city
      + '&position=' + this.position, {
        'action': 'send_register',
        'name': this.name,
        'email': this.email,
        'position': this.position,
        'school': this.school,
        'city': this.city,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).subscribe((res) => {
          // console.log("Created a customer");
          if (res.success) {
            return this.registered = true;
          } else {
            return false;
          }
      });

      return this.registered = true;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Page } from '../../interfaces/page';
import { Post } from '../../interfaces/posts';
import { Observable } from 'rxjs/Observable';
// import { MailchimpComponent } from '../mailchimp/mailchimp.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  page$: Observable<Page>;
  page: Page;
  locations$: Observable<Post[]>;
  locations: Post[];
  modal: Boolean[];

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.page$ = this.route.data.map(r => r.content);
    this.locations$ = this.route.data.map(r => r.posts);

    this.page$.subscribe((page) => {
      this.page = page;
    });

    this.locations$.subscribe((locations) => {
      this.locations = locations;
    });
  }

  openModal(element_id) {
    // let element = document.getElementById('exampleDiv');
    this.modal[element_id] = true;
  }

}
